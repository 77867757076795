<template>
  <section class="mb-40 lg:mb-50" :data-aller-id="sectionId">
    <WidthWrapper v-if="brands.length">
      <LazySiteProducts
        v-if="componentContent.layout === 'grid' && brandsFilter.length"
        :filter="brandsFilter"
      />
      <LazySnapSlider
        v-else
        :slider-conf="{
          items: 1,
          stagePadding: '.2',
          slideClasses: 'max-w-[80vw] lg:max-w-[320px]',
          responsive: [
            {
              breakpoint: 600,
              items: 2,
              stagePadding: '.2',
            },
            {
              breakpoint: 768,
              items: 3,
              stagePadding: '.2',
            },
            {
              breakpoint: 1024,
              items: 4,
              stagePadding: 0
            },
          ],
        }"
        :items="brands.filter((brand) => brand.media?.hero)"
        class="-ml-5 -mr-16 lg:-mr-5"
      >
        <template #sliderHeader>
          <SecondaryTitle
            v-if="componentContent.title"
            :title="componentContent.title"
            class="pl-5"
          />
        </template>
        <template v-for="(brand, index) in brands.filter((brand) => brand.media?.hero)" #[index] :key="brand.publicationCode">
          <article class="h-full">
            <NuxtLink
              :to="brand.url"
              class="group relative block overflow-hidden rounded-lg text-white"
              :aria-label="brand.title"
              @click="trackClick('click_promotion_element', brand)"
            >
              <div class="aspect-[243/212] overflow-hidden">
                <img v-if="brand.media?.hero?.imageUrl" :alt="`${brand.title} hero`" :src="`${brand.media?.hero?.imageUrl}?width=243&height=212&format=webp`" :srcset="`${brand.media?.hero?.imageUrl}?width=243&height=212&format=webp 243w, ${brand.media?.hero?.imageUrl}?width=486&height=424&format=webp 486w, ${brand.media?.hero?.imageUrl}?width=729&height=636&format=webp 729w`" sizes="(min-width:1024px) 285px, (min-width:768px) 30vw, 80vw" width="243" height="212" loading="lazy" class="scale-[1.02] transition-transform duration-500 will-change-transform group-hover:scale-110">
              </div>
              <div class="relative flex flex-col items-center p-20">
                <img v-if="brand.media?.hero?.imageUrl" :alt="`${brand.title} hero`" :src="`${brand.media?.hero?.imageUrl}?width=243&height=212&format=webp`" :srcset="`${brand.media?.hero?.imageUrl}?width=243&height=212&format=webp 243w, ${brand.media?.hero?.imageUrl}?width=486&height=424&format=webp 486w, ${brand.media?.hero?.imageUrl}?width=729&height=636&format=webp 729w`" sizes="(min-width:1024px) 285px, (min-width:768px) 30vw, 80vw" width="243" height="212" loading="lazy" class="absolute inset-0 -z-10 h-full w-full">
                <div class="absolute inset-0 -z-10 bg-black/30 backdrop-blur-3xl" />
                <div class="mb-15 flex h-[50px] items-center justify-center">
                  <img v-if="brand.logo?.imageUrl" :src="brand.logo?.imageUrl" :alt="`${brand.title} logo`" class="h-full max-w-[80%] invert" width="77" height="50" loading="lazy">
                </div>
                <div class="line-clamp-1">
                  {{ brand.tagline }}
                </div>
              </div>
            </NuxtLink>
          </article>
        </template>
      </LazySnapSlider>
    </WidthWrapper>
    <div v-else class="flex min-h-[175px] items-center justify-center text-20">
      <AnimationLoading />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { BrandTransformer, type BrandSelectorBlock, type ContentPageBrand } from '@aller/nuxt-allerservice-sdk/transformers';
import type { OptimizelyContentResponse } from '@aller/nuxt-allerservice-sdk/types';

const props = defineProps({
  componentContent: {
    type: Object as PropType<BrandSelectorBlock>,
    required: true,
  },
});

const brands = ref<ContentPageBrand[] | []>([]);
const brandsFilter = ref<{ url: string; title: string } []>([]);

// Either get the directly selected brands, or fallback to the global ones from useState.
// The call is made in mounted (aka client) to minimize server response time.
// This may increase CLS score if the component is in view before the load/render is finished.
onMounted(async () => {
  if (props.componentContent.brandGuids) {
    // Quick'n'dirty change the fetching do useAPI directly.
    // @TODO useOptimizely().content().byIds(props.componentContent.brandGuids currently does not support clientside-only well. And causes errors sometimes when used like this.
    // @TODO refactor this to make it pretty and reuseable.
    const token = useCookie('nuxt-token').value || useRequestEvent()?.context.nuxtToken as string;
    const { apiOptimizelyDomain, apiOptimizelyVersion } = useRuntimeConfig().public as Record<string, string | number>;
    const fields = [
      'title', 'url', 'logo', 'heroMedia', 'publicationCode', 'tagline', 'contentLink',
    ];
    const { data, execute } = await useAPI<OptimizelyContentResponse[]>(`https://${apiOptimizelyDomain}/api/episerver/${apiOptimizelyVersion}/content`, {
      query: {
        guids: props.componentContent.brandGuids.join(','),
        expand: fields.join(','),
        select: fields.join(','),
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Encoding': 'br;q=1.0, gzip;q=0.8, *;q=0.1',
        'Accept-Language': 'da',
        'Content-Type': 'application/json',
      },
      server: false,
      lazy: true,
    });
    await execute();
    brands.value = data?.value?.map((brand) => BrandTransformer(brand)) || [];
    brandsFilter.value = brands.value.map((brand) => ({
      url: brand.url!,
      title: brand.title,
    }));
  } else {
    brands.value = useState<ContentPageBrand[]>('brands').value || ref([]);
  }
});

const sectionId = `content-brand-selector-${props.componentContent.layout}`;

const trackClick = (event: string, item) => {
  useGTM().pushElement(event, {
    section_id: sectionId,
    section_text: props.componentContent.title,
    element_id: item.guid,
    element_text: item.title,
    element_link: item.url,
  });
};

onMounted(() => {
  if (props.componentContent.layout !== 'grid') {
    brands.value.forEach((brand) => {
      trackClick('view_promotion_element', brand);
    });
  }
});
</script>
